import * as optimismSDK from '@eth-optimism/sdk';

import * as env from '../env';

const l2ChainId = Number(env.L2_CHAIN_ID);
const selfAddressManager = env.AddressManager;
const selfL1CrossDomainMessenger = env.L1CrossDomainMessenger;
const selfL1StandardBridge = env.L1StandardBridge;
const selfStateCommitmentChain = env.StateCommitmentChain;
const selfCanonicalTransactionChain = env.CanonicalTransactionChain;
const selfBondManager = env.BondManager;
const selfOptimismPortal = env.OptimismPortal;
const selfL2OutputOracle = env.L2OutputOracle;

export const contracts = optimismSDK.getAllOEContracts(l2ChainId, {
  overrides: {
    l1: {
      AddressManager: selfAddressManager,
      L1CrossDomainMessenger: selfL1CrossDomainMessenger,
      L1StandardBridge: selfL1StandardBridge,
      StateCommitmentChain: selfStateCommitmentChain,
      CanonicalTransactionChain: selfCanonicalTransactionChain,
      BondManager: selfBondManager,
      OptimismPortal: selfOptimismPortal,
      L2OutputOracle: selfL2OutputOracle,
    },
  },
});

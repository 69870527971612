import { useCallback, useEffect, useState, useMemo } from 'react';
import { Flex, Input, Button, Box, Link } from '@totejs/uikit';
import { ReverseVIcon } from '@totejs/icons';
import { useNetwork, useAccount } from 'wagmi';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import {
  SwitchNetworkButton,
  useDebounce,
  mobileMedia,
  useCheckErc20TokenMatch,
  useGetPrice,
  useGetTokenList,
  useTxSuccessful,
  TxError,
  WaitingForConfirmation,
  GA_MAP,
  reportEvent,
} from '@op-bridge/bridge-core';

import { TokenSelection } from './TokenSelection';
import { BSCLogo } from './svgIcon/BSCLogo';
import { Loading } from './Loading';
import {
  useChainBalance,
  useDeposit,
  useGetChainProviders,
  useGetGasPrice,
  useInputValidator,
  useGetAllowance,
} from '../hooks';
import * as env from '../env';
import { ConnectButton } from './ConnectButton';
import { DepositSuccessful } from './modal/DepositSuccessful';
import { batchUpdate, removeTrailingZero } from '../utils';
import ThirdPartyBridge from './ThirdPartyBridge';
import { ERC20DepositButton } from './ERC20DepositButton';
import { ApproveButton } from './ApproveButton';
import { bep20TokenList } from '../env/bep20TokenList';
import ComboLogo from './svgIcon/ComboLogo';
export const handleKeyPress = (e: React.KeyboardEvent) => {
  // only allow number and decimal
  if (
    e.key !== '1' &&
    e.key !== '2' &&
    e.key !== '3' &&
    e.key !== '4' &&
    e.key !== '5' &&
    e.key !== '6' &&
    e.key !== '7' &&
    e.key !== '8' &&
    e.key !== '9' &&
    e.key !== '0' &&
    e.key !== '.' &&
    e.key !== ',' &&
    e.key !== '。' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Backspace'
  ) {
    e.preventDefault();
  }
};

const min = 0.00000001;
const statusMap = {
  1: 'Amount is required.',
  2: 'Insufficient balance.',
  3: `Please enter a minimum amount of 0.00000001.`,
};

const Deposit = ({
  handleTypeChange,
  tokenType,
}: {
  handleTypeChange: (type: string) => void;
  tokenType: string;
}) => {
  const { address, isConnected, isConnecting, isReconnecting, isDisconnected } = useAccount();

  const {
    deposit,
    isSufficient,
    txFailedMsg,
    showError,
    waitingConfirmation,
    showPending,
    handlePendingShow,
    handleErrorShow,
  } = useDeposit();
  const router = useRouter();
  const { chain: currentChain } = useNetwork();
  const { l1Chain, l2Chain } = useGetChainProviders();
  const {
    getEstimateGas,
    getGasPrice,
    isLoadingPrice,
    isLoadingGasPrice,
    getEstimateApproveGas,
    getEstimateERC20Gas,
  } = useGetGasPrice();

  const { tokensList } = useGetTokenList({
    tokenList: bep20TokenList,
    isNotUseGitHubToken: true,
  });
  const { getAllowance, isLoadingAllowance } = useGetAllowance();
  const { status, changeVal, setMax } = useInputValidator(0, min);
  const { showSuccessful, handleSuccessfulShow } = useTxSuccessful();
  const { data: tokenPrice } = useGetPrice(tokenType);
  const { data: bnbPrice } = useGetPrice('BNB');
  const [tokenAllowance, setTokenAllowance] = useState<number>(0);
  const [depositVal, setDepositVal] = useState('');
  const [txHash, setTxHash] = useState('');
  const [estimateGas, setEstimateGas] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [isCalcPrice, setIsCalcPrice] = useState(false);
  const [estimateGasError, setEstimateGasError] = useState('');

  const asset = useMemo(() => {
    return tokensList.filter((asset) => asset.symbol === tokenType);
  }, [tokenType, tokensList]);

  const {
    l1BalanceVal,
    l2BalanceVal,
    setL1BalanceVal,
    setL2BalanceVal,
    updateBalance,
    isLoading: isLoadingTokenBalance,
  } = useChainBalance(tokenType);

  setMax(l1BalanceVal ? Number(l1BalanceVal) : 0);

  const handleAllowanceUpdate = (amount: number) => {
    setTokenAllowance(Number(amount));
  };

  const { checkTokenAddress } = useCheckErc20TokenMatch(env.L2_RPC_URL);
  const loadGasPrice = async (value: string) => {
    try {
      setEstimateGasError('');
      const inputState = changeVal(Number(value));
      if (inputState === 0 && tokenType === 'BNB') {
        // eslint-disable-next-line no-console
        // console.log('estimate gas input value', value);
        const SDKgas = await getEstimateGas(value, 'deposit');
        const gasPrice = await getGasPrice(env.L1_RPC_URL);
        // BNB to gwei
        if (SDKgas?.error) {
          setEstimateGasError(SDKgas?.msg);
          handleErrorShow(true);
          return;
        }
        if (SDKgas && SDKgas?.val && gasPrice) {
          setEstimateGas(Number(SDKgas.val));
          setGasPrice(Number(gasPrice));
          updateBalance();
        } else {
          // eslint-disable-next-line no-console
          console.log('error getting gas price', SDKgas, gasPrice);
        }
        setIsCalcPrice(false);
      } else if (inputState === 0 && asset && asset?.length) {
        // ERC20 tokens
        let SDKgas = null;

        // Check token match
        try {
          const pass = await checkTokenAddress(asset[0]);
          if (!pass) {
            throw Error('Your selected token is under maintenance, please try again later.');
          }
        } catch (e: any) {
          setEstimateGasError(e);
          handleErrorShow(true);
          setIsCalcPrice(false);
          return;
        }

        const latestAllowance = await getAllowance(tokenType);
        // eslint-disable-next-line no-console
        // console.log(
        //   'input value',
        //   value,
        //   'latest allowance',
        //   Number(latestAllowance) * Math.pow(10, -18),
        // );

        if (!isLoadingAllowance) {
          if (Number(latestAllowance) * Math.pow(10, -18) < Number(value)) {
            SDKgas = await getEstimateApproveGas(asset[0].l1Address, asset[0].l2Address, value);
          } else {
            SDKgas = await getEstimateERC20Gas(
              asset[0].l1Address,
              asset[0].l2Address,
              value,
              'deposit',
            );
          }
        } else {
          // if still loading allowance set set estimate gas to 0
          // eslint-disable-next-line no-console
          console.log('still fetching the latest allowance');
          setEstimateGas(0);
        }
        const gasPrice = await getGasPrice(env.L1_RPC_URL);
        // BNB to gwei
        if (SDKgas?.error) {
          setEstimateGasError(SDKgas?.msg);
          handleErrorShow(true);
          setIsCalcPrice(false);
          return;
        }
        if (SDKgas && SDKgas?.val && gasPrice) {
          setGasPrice(Number(gasPrice));
          setEstimateGas(Number(SDKgas.val));
          updateBalance();
        } else {
          // eslint-disable-next-line no-console
          console.log('error getting gas price', SDKgas, gasPrice);
        }
        setIsCalcPrice(false);
      } else {
        setEstimateGas(0);
        setIsCalcPrice(false);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
      setEstimateGasError(e);
      batchUpdate(() => {
        setEstimateGas(0);
        setIsCalcPrice(false);
      });
    }
  };

  // For approve button
  const instanceCalcPriceFee = (value: string) => {
    loadGasPrice(value);
  };

  // Trigger when input changed
  const calcPriceFee = useDebounce(async (value: string) => {
    loadGasPrice(value);
  });

  useEffect(() => {
    if (isDisconnected) {
      batchUpdate(() => {
        setDepositVal('');
        setL1BalanceVal(0);
        setL2BalanceVal(0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  // Refresh Bep20 Token allowance
  useEffect(() => {
    if (tokenType !== 'BNB') {
      getAllowance(tokenType).then((res) => {
        handleAllowanceUpdate(Number(res) * Math.pow(10, -18));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenType, address]);

  const handleChange = (e: React.ChangeEvent) => {
    let value = (e.target as HTMLInputElement).value;
    // update full stop to decimal in chinese typing
    value = value.replace('。', '.');
    value = value.replace(',', '.');
    const decimalLength = value.split('.')[1]?.length || 0;
    //Equal to zero, less than 0.00000001, more than 8 decimals or more than 20 digits
    if (
      (Number(value) !== 0 && Number(value) < min) ||
      value?.length > 20 ||
      decimalLength > 8 ||
      value.split('.').length > 2
    ) {
      return;
    }

    try {
      let filteredValue = value;
      if (value === '.') {
        filteredValue = '0.';
      }
      // const decimalStart = /(?:^\.)|(?:^0(\d+))/g;
      // if (decimalStart.test(filteredValue)) {
      //   filteredValue = filteredValue.replace(decimalStart, (...args) => {
      //     return args[1] || args[2] ? '0.' + (args[1] || args[2]) : '';
      //   });
      //   setDepositVal(filteredValue);
      // } else {

      if (!isNaN(Number(filteredValue))) {
        setDepositVal(filteredValue);
      } else {
        setDepositVal('0');
      }

      if (filteredValue && !isNaN(Number(filteredValue))) {
        if (Number(filteredValue) !== 0) {
          if (currentChain && currentChain.id === Number(env.L1_CHAIN_ID)) {
            setIsCalcPrice(true);
            calcPriceFee(filteredValue);
          }
        } else {
          setEstimateGas(0);
        }
      } else {
        // setEstimateGas(0);
        // setDepositVal('');
        changeVal(min);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  // When user leaves an input field
  const handleBlur = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    if (Number(value) === 0) {
      changeVal(0);
    }
    if (value[0] === '.') {
      const newValue = '0.' + value.split('.')[1];
      setDepositVal(newValue);
      if (Number(newValue) !== 0) {
        if (currentChain && currentChain.id === Number(env.L1_CHAIN_ID)) {
          setIsCalcPrice(true);
          calcPriceFee(newValue);
        }
      }
    }
  };

  const clearInput = useCallback(() => {
    batchUpdate(() => {
      setDepositVal('');
      setTxHash('');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTokenSelection = useCallback(
    (tokenName: string) => {
      batchUpdate(() => {
        handleTypeChange(tokenName);
        setDepositVal('');
      });
    },
    [handleTypeChange],
  );

  // Remove Cake from deposit token list
  let filteredList = tokensList;
  if (env.NET_ENV === 'Mainnet') {
    // if (/localhost|fe\.nodereal\.cc|^(\d|\.)+$/.test(window.location.origin)) {
    filteredList = tokensList.filter(
      (item) =>
        item?.extensions?.officialBridgeStatus !== 'depriving' &&
        item?.extensions?.officialBridgeStatus !== 'deprived' &&
        item.symbol !== 'Cake',
    );
    // } else {
    // filteredList = tokensList.filter((item) => item.symbol !== 'Cake');
    // }
  } else {
    filteredList = tokensList.filter(
      (item) =>
        item?.extensions?.officialBridgeStatus !== 'depriving' &&
        item?.extensions?.officialBridgeStatus !== 'deprived',
    );
  }
  return (
    <Container>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex alignItems={'center'} mb={4}>
          <FromText>From</FromText>
          <IconWrapper>
            <BSCLogo width={15} height={15} type="deposit1" />
          </IconWrapper>
          <ChainName>{l1Chain ? l1Chain[0]?.name : ''}</ChainName>
        </Flex>

        {env.NET_ENV === 'Testnet' ? (
          <Faucet
            textDecoration={'underline'}
            href={`https://testnet.binance.org/faucet-smart/`}
            target="_blank"
            onClick={() => {
              reportEvent({
                name: GA_MAP.faucet,
                data: { name: 'Faucet Link Click' },
              });
            }}
          >
            Faucet
          </Faucet>
        ) : null}
      </Flex>
      <Flex
        flexDirection={'column'}
        gap={4}
        gridGap={4}
        alignItems={'flex-start'}
        padding={'12px 16px'}
        background={'bg.codebox'}
        borderRadius={10}
      >
        <Box color={'readable.secondary'}>Amount</Box>
        <InputWrapper>
          <StyledInput
            disabled={
              !isConnected ||
              !!isReconnecting ||
              !!isConnecting ||
              !currentChain ||
              currentChain.id !== Number(env.L1_CHAIN_ID)
            }
            type={'text'}
            value={depositVal}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onWheel={(e: any) => e.target.blur()}
            onBlur={handleBlur}
            placeholder="0"
            className={status !== 0 ? 'error' : ''}
          ></StyledInput>
          <InputIcon>
            <TokenSelection
              value={tokenType}
              type="deposit"
              assetList={filteredList}
              handleTokenSelection={handleTokenSelection}
              onMenuButtonClick={() => {
                reportEvent({
                  name: GA_MAP.depositTokenSelectionButton,
                  data: { name: 'Deposit Token Selection' },
                });
              }}
              l1ChainId={env.L1_CHAIN_ID}
              l2ChainId={env.L2_CHAIN_ID}
              l1Rpc={env.L1_RPC_URL}
              l2Rpc={env.L2_RPC_URL}
              loadingComponent={<Loading />}
            ></TokenSelection>
          </InputIcon>
        </InputWrapper>
        {status > 0 ? (
          <InputWaring>{statusMap[status as keyof typeof statusMap]}</InputWaring>
        ) : null}
        <L1Balance>
          Balance:{' '}
          {!isLoadingTokenBalance
            ? `${removeTrailingZero(l1BalanceVal?.toFixed(8))} ${tokenType}`
            : ''}{' '}
          {l1BalanceVal && !isNaN(tokenPrice) && !isLoadingTokenBalance
            ? `($${Number(tokenPrice * Number(Number(l1BalanceVal))).toLocaleString('fullwide', {
                maximumFractionDigits: 2,
              })})`
            : null}
        </L1Balance>
      </Flex>

      <Flex flex={1} justifyContent={'center'} m={1}>
        <Arrow
          onClick={() => {
            reportEvent({ name: GA_MAP.depositSwitch, data: { name: 'Click Deposit Switch' } });
            router.push('/withdraw');
          }}
        >
          <ReverseVIcon width={16} height={16} color={'bg.middle'} />
        </Arrow>
      </Flex>

      <Flex width={'100%'} alignItems={'center'} mb={4}>
        <FromText>To</FromText>
        <IconWrapper>
          <ComboLogo width={15} height={15} type="deposit2" />
        </IconWrapper>
        <ChainName>{l2Chain ? l2Chain[0]?.name : ''}</ChainName>
      </Flex>

      <Flex
        flexDirection={'column'}
        className="mobile-bottom-24"
        alignItems={'flex-start'}
        padding={16}
        background={'bg.codebox'}
        borderRadius={10}
        mb={[24, 16]}
      >
        <Receive>
          You will receive:{' '}
          <ReceiveAmount>
            {depositVal || 0} {tokenType}
          </ReceiveAmount>
        </Receive>

        <L2Balance>
          Current balance:{' '}
          {!isLoadingTokenBalance
            ? `${removeTrailingZero(l2BalanceVal?.toFixed(8)) || 0} ${tokenType}`
            : ''}{' '}
          {l2BalanceVal && !isNaN(tokenPrice) && !isLoadingTokenBalance
            ? `($${Number(tokenPrice * Number(l2BalanceVal)).toLocaleString('fullwide', {
                maximumFractionDigits: 2,
              })})`
            : ''}
        </L2Balance>
      </Flex>

      {depositVal &&
      Number(depositVal) !== 0 &&
      currentChain &&
      currentChain.id === Number(env.L1_CHAIN_ID) ? (
        <Flex
          className="mobile-bottom-24"
          gap={[0, 12, 12]}
          gridGap={[0, 12, 12]}
          flexDirection={'column'}
          mb={[24, 16]}
        >
          <PriceWrapper>
            <PriceText>Gas Fee</PriceText>
            <PriceValue>
              {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && tokenPrice ? (
                `${removeTrailingZero(
                  Number(
                    estimateGas *
                      Math.pow(10, 18) *
                      gasPrice *
                      ((tokenAllowance !== null && tokenAllowance >= Number(depositVal)) ||
                      tokenType === 'BNB'
                        ? 1.5
                        : 1),
                  )?.toFixed(8),
                )} ${env.TOKEN_SYMBOL} ($${Number(
                  estimateGas *
                    Math.pow(10, 18) *
                    bnbPrice *
                    gasPrice *
                    ((tokenAllowance !== null && tokenAllowance >= Number(depositVal)) ||
                    tokenType === 'BNB'
                      ? 1.5
                      : 1),
                ).toLocaleString('fullwide', {
                  maximumFractionDigits: 2,
                })})`
              ) : (
                <Loading />
              )}
            </PriceValue>
          </PriceWrapper>
          {tokenType === 'BNB' ? (
            <PriceWrapper>
              <PriceText>Total Amount</PriceText>
              <PriceValue>
                {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && bnbPrice ? (
                  `${removeTrailingZero(
                    Number(
                      Number(estimateGas) * Math.pow(10, 18) * gasPrice * 1.5 + Number(depositVal),
                    )?.toFixed(8),
                  )} ${env.TOKEN_SYMBOL} ($${Number(
                    bnbPrice *
                      Number(
                        Number(estimateGas * Math.pow(10, 18) * gasPrice * 1.5) +
                          Number(depositVal),
                      ),
                  ).toLocaleString('fullwide', {
                    maximumFractionDigits: 2,
                  })})`
                ) : (
                  <Loading />
                )}
              </PriceValue>
            </PriceWrapper>
          ) : tokenAllowance !== null && tokenAllowance >= Number(depositVal) ? (
            // Hide total amount when token allowance is not enough
            <PriceWrapper>
              <PriceText>Total amount</PriceText>
              <PriceValue>
                {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && tokenPrice ? (
                  `${removeTrailingZero(
                    Number(depositVal)?.toFixed(8),
                  )} ${tokenType} + ${removeTrailingZero(
                    Number(
                      estimateGas *
                        Math.pow(10, 18) *
                        gasPrice *
                        (tokenAllowance !== null && tokenAllowance >= Number(depositVal) ? 1.5 : 1),
                    )?.toFixed(8),
                  )} BNB ($${Number(
                    bnbPrice *
                      Number(
                        estimateGas *
                          Math.pow(10, 18) *
                          gasPrice *
                          (tokenAllowance !== null && tokenAllowance >= Number(depositVal)
                            ? 1.5
                            : 1),
                      ) +
                      Number(depositVal) * tokenPrice,
                  ).toLocaleString('fullwide', {
                    maximumFractionDigits: 2,
                  })})`
                ) : (
                  <Loading />
                )}
              </PriceValue>
            </PriceWrapper>
          ) : null}
          {((tokenAllowance !== null && tokenAllowance >= Number(depositVal)) ||
            tokenType === 'BNB') && (
            <PriceWrapper>
              <PriceText>Estimated Time of Arrival</PriceText>
              <PriceValue>60s</PriceValue>
            </PriceWrapper>
          )}
        </Flex>
      ) : null}

      {!isConnected && !isReconnecting ? <ConnectButton type="deposit" /> : null}

      {/* BNB deposit */}
      {isConnected &&
      !isReconnecting &&
      !isConnecting &&
      currentChain &&
      currentChain.id === Number(env.L1_CHAIN_ID) &&
      tokenType === 'BNB' ? (
        <DepositButton
          disabled={!isSufficient || !!status || !depositVal || isCalcPrice}
          onClick={() => {
            reportEvent({ name: GA_MAP.deposit, data: { name: 'Click Deposit' } });
            try {
              let gasLimit = Math.ceil(Number(estimateGas) * Math.pow(10, 18) * 1.5);
              if (!gasLimit || gasLimit < 300000) {
                gasLimit = 300000;
              }
              deposit(depositVal, gasLimit).then((res) => {
                if (res) {
                  setTxHash(res);
                  handleSuccessfulShow(true);
                }
              });
            } catch (e: any) {
              // eslint-disable-next-line no-console
              console.log(e);
            }
            updateBalance();
          }}
        >
          Deposit
        </DepositButton>
      ) : null}

      {/* Bep20 token deposit */}
      {isConnected &&
      !isReconnecting &&
      !isConnecting &&
      currentChain &&
      currentChain.id === Number(env.L1_CHAIN_ID) &&
      tokenType !== 'BNB' &&
      tokenAllowance &&
      tokenAllowance !== 0 &&
      tokenAllowance >= Number(depositVal) ? (
        <ERC20DepositButton
          disabled={
            !isSufficient ||
            !!status ||
            !Number(depositVal) ||
            isLoadingAllowance ||
            isCalcPrice ||
            estimateGas === 0
          }
          setTxHash={setTxHash}
          asset={asset}
          estimateGas={estimateGas}
          depositVal={depositVal}
          updateBalance={updateBalance}
          handleAllowanceUpdate={handleAllowanceUpdate}
          handleSuccessfulShow={handleSuccessfulShow}
        />
      ) : null}

      {/* Approve allowance button */}
      {tokenType !== 'BNB' &&
      tokenAllowance !== null &&
      asset &&
      asset?.length > 0 &&
      currentChain &&
      currentChain.id === Number(env.L1_CHAIN_ID) &&
      (tokenAllowance === 0 || tokenAllowance < Number(depositVal)) ? (
        <ApproveButton
          asset={asset}
          disabled={!Number(depositVal) || isLoadingAllowance || l1BalanceVal === 0}
          amount={depositVal}
          allowance={tokenAllowance}
          updateEstimateGas={() => {
            // eslint-disable-next-line no-console
            // console.log('approve deposit amount', depositVal);
            instanceCalcPriceFee(depositVal);
          }}
          tokenType={tokenType}
          handleAllowanceUpdate={handleAllowanceUpdate}
        />
      ) : null}

      {currentChain &&
      currentChain.id !== Number(env.L1_CHAIN_ID) &&
      isConnected &&
      !isReconnecting &&
      !isConnecting ? (
        <SwitchNetworkButton id={Number(env.L1_CHAIN_ID)} />
      ) : null}

      {/* Third party buttons */}
      <ThirdPartyBridge />

      {/* Modal components below */}
      {waitingConfirmation ? (
        <WaitingForConfirmation theme="light" isOpen={showPending} handleOpen={handlePendingShow} />
      ) : null}

      {(txFailedMsg || estimateGasError) && (
        <TxError
          theme="light"
          isOpen={showError}
          title="Transaction Failed"
          handleOpen={() => {
            handleErrorShow(false);
          }}
          errorCode={txFailedMsg || estimateGasError}
          clearInput={clearInput}
        />
      )}

      {txHash && (
        <DepositSuccessful
          isOpen={showSuccessful}
          handleOpen={handleSuccessfulShow}
          txHash={txHash}
          clearInput={clearInput}
        />
      )}
    </Container>
  );
};

export default Deposit;

export const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: ${(props: any) => props.theme.colors.bg?.bottom};
  margin: 0 8px;
`;

const Faucet = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: ${(props: any) => props.theme.colors.readable.link};

  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
`;

export const StyledInput = styled(Input)`
  height: 52px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  border-radius: 8px;
  padding: 11px;
  color: ${(props: any) => props.theme.colors.readable.normal};
  background-color: ${(props: any) => props.theme.colors.bg.middle};
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  &:disabled {
    background-color: #fff;
    opacity: 1;
  }
  &:hover {
    border-color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
  &.error {
    border-color: ${(props: any) => props.theme.colors.scene.danger.active};
  }
  &::placeholder {
    color: ${(props: any) => props.theme.colors.readable.secondary};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 9;
`;

export const InputIcon = styled(Flex)`
  position: absolute;
  gap: 12px;
  grid-gap: 12px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
`;

export const DepositButton = styled(Button)`
  line-height: 20px;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors?.bg?.card};
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.secondary};
      background: ${(props: any) => props.theme.colors.readable?.disabled};
      opacity: 1;
    }
  }
`;

export const FromText = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
  line-height: 21px;
  color: ${(props: any) => props.theme.colors.readable?.normal};
`;

export const ChainName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-left: -4px;
  color: ${(props: any) => props.theme.colors.readable?.normal};
`;

const L1Balance = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const L2Balance = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

export const Arrow = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: any) => props.theme.colors.scene.primary.normal};
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: ${(props: any) => props.theme.colors.scene.primary.active};
  }
`;

export const ReceiveAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-left: 4px;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

export const Receive = styled(Flex)`
  color: ${(props: any) => props.theme.colors.readable.secondary};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Container = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  grid-gap: 8px;
  ${mobileMedia} {
    gap: 0;
    & > div:not(.mobile-bottom-24):not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const PriceWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
`;

export const PriceText = styled(Box)`
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${(props: any) => props.theme.colors.readable.normal};
  ${mobileMedia} {
    padding: 5.5px 0;
  }
`;

export const PriceValue = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: ${(props: any) => props.theme.colors.readable.normal};
  ${mobileMedia} {
    padding: 5.5px 0;
  }
`;

export const InputWaring = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  text-align: right;
  letter-spacing: -0.022em;

  color: ${(props: any) => props.theme.colors.scene.danger.active};
`;

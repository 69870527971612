import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { useCallback, useState } from 'react';
import { TokenObjectType, useGetTokenList } from '@op-bridge/bridge-core';

import * as env from '../env';
import { bep20TokenList } from '../env/bep20TokenList';

const l1RpcProvider = new ethers.providers.JsonRpcProvider(env.L1_RPC_URL, 'any');

export const useGetAllowance = () => {
  const { address } = useAccount();
  const [isLoadingAllowance, setIsLoadingAllowance] = useState(false);
  const { tokensList, isLoading } = useGetTokenList({
    tokenList: bep20TokenList,
    isNotUseGitHubToken: true,
  });

  const getAllowance = useCallback(
    async (tokenType: string) => {
      try {
        if (address && (env.NET_ENV === 'Testnet' || (env.NET_ENV === 'Mainnet' && !isLoading))) {
          const asset = tokensList.filter((asset: TokenObjectType) => asset.symbol === tokenType);
          if (Array.isArray(asset) && asset?.length) {
            setIsLoadingAllowance(true);
            const l1ERC20 = new ethers.Contract(asset[0].l1Address, asset[0].ABI, l1RpcProvider);
            const allowance = await l1ERC20.allowance(address, env.L1StandardBridge);
            // console.log(allowance);
            setIsLoadingAllowance(false);
            return allowance;
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [address, isLoading, tokensList],
  );
  return { getAllowance, isLoadingAllowance };
};

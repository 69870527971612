import * as ethers from 'ethers';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';
import { useResponsive, useTxFailed, usePending } from '@op-bridge/bridge-core';

import { useGetCrossChainMessenger } from './useGetCrossChainMessenger';
import * as env from '../env';
import { getEtherFormatValue } from '../utils';
const l2RPC = env.L2_RPC_URL;

const l2RpcProvider = new ethers.providers.JsonRpcProvider(l2RPC, 'any');
export const useWithdraw = () => {
  const [isSufficient, setIsSufficient] = useState(false);
  const [txFailedMsg, setTxFailedMsg] = useState('');
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);

  const [address, setAddress] = useState<null | string>(null);
  const { isMobile } = useResponsive();

  const { showPending, handlePendingShow, handlePendingToggle } = usePending();
  const { showError, handleErrorShow, handleErrorToggle } = useTxFailed();

  const { address: wagmiAddress } = useAccount();

  const { crossChainMessenger } = useGetCrossChainMessenger();

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  // check account balance
  useEffect(() => {
    if (address) {
      reportBalances().then((balance) => {
        if (balance) {
          setIsSufficient(true);
        }
      });
    }
    return () => {
      setIsSufficient(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const reportBalances = async () => {
    if (address) {
      try {
        const balanceObj = await l2RpcProvider.getBalance(address);
        const balance = await getEtherFormatValue(balanceObj);
        if (balance) {
          return balance;
        } else {
          return null;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return null;
      }
    }
  };

  const withdraw = async (withdrawAmount: string) => {
    const balance = await reportBalances();
    if (!balance) {
      setIsSufficient(false);
      return;
    }

    if (crossChainMessenger && withdrawAmount) {
      try {
        // show pending modal
        handlePendingShow(true);
        setWaitingConfirmation(true);

        // start withdraw
        const response = await crossChainMessenger.withdrawETH(
          ethers.utils.parseUnits(withdrawAmount, 'ether'),
        );

        await response.wait();
        // end withdraw

        setWaitingConfirmation(false);
        handlePendingShow(false);

        return response.hash;
      } catch (e: any) {
        setWaitingConfirmation(false);
        handlePendingShow(false);

        if (e && e?.code) {
          // eslint-disable-next-line no-console
          console.log(e?.code);
          if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
            // eslint-disable-next-line no-console
            console.log(e.replacement);
            return e.replacement.hash;
          } else {
            const errorMsg = isMobile ? e?.message || e?.code : e?.code;
            setTxFailedMsg(errorMsg);
            handleErrorShow(true);
          }
        } else {
          setTxFailedMsg(e || 'Transaction Failed');
        }
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  };

  return {
    withdraw,
    isSufficient,
    waitingConfirmation,
    showPending,
    handlePendingShow,
    handlePendingToggle,
    txFailedMsg,
    showError,
    handleErrorShow,
    handleErrorToggle,
  };
};

import { Modal, Flex, ModalCloseButton, Box, Checkbox, Button } from '@totejs/uikit';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  SwitchNetworkButton,
  TokenObjectType,
  mobileMedia,
  useWithdrawHistory,
  TxError,
  usePausedStatus,
  GA_MAP,
  reportEvent,
} from '@op-bridge/bridge-core';
import { ColoredWarningIcon } from '@totejs/icons';
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';

import * as env from '../../env';
import { DepositButton } from '../Deposit';
import {
  addDataIntoCache,
  getDataFromCache,
  batchUpdate,
  removeTrailingZero,
  deleteDataFromCache,
} from '../../utils';
import { Loading, SpinImage } from '../Loading';
import { useL1Withdraw } from '../../hooks';
import { TxLoading, WithdrawProgress } from './WithdrawProgress';

const getHistoryCache = async () => {
  if (typeof window !== 'undefined') {
    const cache = await getDataFromCache(window.location.hostname);
    if (cache) {
      // console.log(cache);
      return cache;
    }
  }
};

interface ConfirmPopupProps {
  isOpen: boolean;
  handleOpen: (show: boolean) => void;
  tokenType: string;
  asset: any;
  withdrawFun: any;
  isSufficient: boolean;
  withdrawVal: string;
  clearInput: () => void;
  handleErrorShowErc20: (show: boolean) => void;
  setTxFailedMsgErc20: (msg: string) => void;
  withdrawERC20: (value: string, asset: TokenObjectType) => Promise<string>;
  tokenPrice: number;
  isLoadingPrice: boolean;
  isLoadingGasPrice: boolean;
  isCalcPrice: boolean;
  l1DataGas: number;
  l2GasPrice: number;
  l1GasPrice: number;
  estimateGas: number;
  bnbPrice: number;
}

const TxStatus = [
  '',
  env.NET_ENV === 'Testnet'
    ? `Return in 15 mins to Prove Withdrawal`
    : `Return in 2 hrs to Prove Withdrawal`,
  'Prove Withdrawal',
  env.NET_ENV === 'Testnet'
    ? 'Return in 30 mins to Finalize Withdrawal'
    : 'Return in 7 days to Finalize Withdrawal',
  'Finalize Withdrawal',
  'View in History',
];

export const ConfirmWithdraw = (props: ConfirmPopupProps) => {
  const {
    isOpen,
    handleOpen,
    tokenType,
    asset,
    isSufficient,
    withdrawFun: withdraw,
    withdrawVal,
    handleErrorShowErc20,
    setTxFailedMsgErc20,
    withdrawERC20,
    tokenPrice,
    isLoadingGasPrice,
    isLoadingPrice, // get estimate gas loading
    isCalcPrice,
    l1DataGas,
    l2GasPrice,
    l1GasPrice,
    estimateGas,
    bnbPrice,
  } = props;
  const { isConnected, address: wagmiAddress } = useAccount();
  const { chain: currentChain } = useNetwork();
  const router = useRouter() as any;

  const [initiateHash, setInitHash] = useState(''); // Bep20 token or BNB initiate hash
  const [proveHash, setProveHash] = useState('');
  const [finalizeHash, setFinalizeHash] = useState('');
  const {
    proveWithdraw,
    finalWithdraw,
    txProveFailedMsg,
    setTxProveFailedMsg,
    txFinalizeFailedMsg,
    setTxFinalizeFailedMsg,
    showError,
    handleErrorShow,
  } = useL1Withdraw();
  const [isConfirming, setIsConfirming] = useState(false); // confirm withdraw in wallet

  const [tickBoxOne, setTickBoxOne] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [historyCache, setHistoryCache] = useState<{ [key: string]: string } | null>(null);
  const [currentTx, setCurrentTx] = useState<any>(null);
  const { refetch } = useWithdrawHistory(address, 1, 100);
  const { loadPausedStatus } = usePausedStatus();
  /**
   * step 0 - Not yet initiate withdrawal
   * step 1 - Initiate withdrawal completed, waiting to prove - Return in 2 hrs to Prove Withdrawal
   * step 2 - Ready to prove
   * step 3 - Prove withdrawal completed, waiting to finalize - Finalizing Withdrawal
   * step 4 - Ready to finalize
   * step 5 - Finalize withdrawal completed
   */
  const step =
    initiateHash && !currentTx ? 1 : (currentTx && Number(currentTx.receiptsStatus)) || 0;

  useEffect(() => {
    if (wagmiAddress) setAddress(wagmiAddress);
  }, [wagmiAddress]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      getHistoryCache().then((res) => {
        if (res) {
          setHistoryCache(res);
        } else {
          setHistoryCache(null);
        }
      });
    }
  }, []);

  // Load latest history data for prove withdraw
  const loadHistory = useCallback(async () => {
    refetch()
      .then(async (res) => {
        const { data } = res;
        const historyList = data.list;
        const currentTxReceipt = historyList.filter((tx: any) => {
          return tx.hash === initiateHash;
        })[0];
        batchUpdate(() => {
          setCurrentTx(currentTxReceipt);
          // eslint-disable-next-line no-console
          console.log('currentTxReceipt', currentTxReceipt);
          // Set prove withdraw tx hash
          if (currentTxReceipt?.l1ProveTxHash) {
            setProveHash(currentTxReceipt?.l1ProveTxHash);
          }
          // Set prove finalize tx hash
          if (currentTxReceipt?.l1TxHash && Number(currentTxReceipt?.l1TxHash) !== 0) {
            setFinalizeHash(currentTxReceipt?.l1TxHash);
          }
        });
        // Clean up tx status cache
        const cache = await getHistoryCache();
        // Check whether the status is updated, if updated then remove cache
        if (cache) {
          const cacheStatus = cache[`${initiateHash as string}`];
          if (
            currentTxReceipt &&
            cacheStatus &&
            currentTxReceipt?.receiptsStatus > cacheStatus &&
            typeof window !== 'undefined'
          ) {
            // get updated status from backend, we can remove cache
            deleteDataFromCache(window.location.hostname, initiateHash);
            setHistoryCache(null);
          } else {
            setHistoryCache(cache);
          }
        } else {
          setHistoryCache(null);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        setIsConfirming(false);
      });
  }, [refetch, initiateHash]);

  useEffect(() => {
    if (isConnected && wagmiAddress && initiateHash) {
      // Start loading history immediately when the component mounts
      loadHistory();
      // Set up an interval to call loadHistory every mins
      const interval = setInterval(loadHistory, 1000 * 60);
      return () => clearInterval(interval);
    }
  }, [isConnected, wagmiAddress, loadHistory, initiateHash]);

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        handleOpen(false);
        setCurrentTx(null);
        reportEvent({ name: GA_MAP.withdrawPopupClose, data: { name: 'Close Withdraw Popup' } });
      }}
      margin={0}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <ModalCloseButton color={'readable.pageButton'} />
      <Flex flexDirection={'column'} flex={1}>
        <ModalTitle>Withdrawal</ModalTitle>

        <Flex
          mb={12}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box color={'readable.pageButton'} mb={[4, 0]}>
            Amount to Withdraw:
          </Box>
          <Box>
            {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && tokenPrice ? (
              <>
                <span style={{ fontWeight: '700' }}>{`${removeTrailingZero(
                  Number(withdrawVal)?.toFixed(8),
                )} ${tokenType}`}</span>{' '}
                {`(${
                  Number(Number(withdrawVal) * tokenPrice) < 0.01
                    ? '< $0.01'
                    : `$${Number(Number(withdrawVal) * tokenPrice).toLocaleString('fullwide', {
                        maximumFractionDigits: 2,
                      })}`
                })`}
              </>
            ) : (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            )}
          </Box>
        </Flex>

        <WithdrawProgress
          step={step}
          initiateHash={initiateHash}
          proveHash={proveHash}
          finalizeHash={finalizeHash}
          estimateGas={estimateGas}
          isLoadingL1GasPrice={isLoadingGasPrice || isLoadingPrice || isCalcPrice}
          bnbPrice={bnbPrice}
          l1GasPrice={l1GasPrice}
          l2GasPrice={l2GasPrice}
          l1DataGas={l1DataGas}
          historyCache={historyCache}
        />

        <Box>
          {step < 1 && (
            <Flex flexDirection={'column'} mb={[8, 24]}>
              <StyledCheckbox
                mb={8}
                spacing={8}
                onChange={(e: any) => {
                  try {
                    if (e.target.checked === true) {
                      reportEvent({
                        name: GA_MAP.withdrawPopupCheckBox,
                        data: { name: 'Tick Checkbox' },
                      });
                      setTickBoxOne(true);
                    } else {
                      setTickBoxOne(false);
                    }
                  } catch (error) {
                    setTickBoxOne(false);
                  }
                }}
              >
                {env.NET_ENV === 'Mainnet' ? (
                  <>
                    I understand that I must return after <span>1-2 hours</span> and manually{' '}
                    <span>Prove Withdrawal</span> before the 7-day withdrawal timer starts.
                  </>
                ) : (
                  <>
                    I understand that I must return after <span>15 minutes</span> and manually{' '}
                    <span>Prove Withdrawal</span> before the 30-minute withdrawal timer starts.
                  </>
                )}
              </StyledCheckbox>
            </Flex>
          )}

          {initiateHash &&
          (step === 2 || step === 4) &&
          isConnected &&
          currentChain &&
          currentChain.id !== Number(env.L1_CHAIN_ID) ? (
            <SwitchNetworkButton
              id={Number(env.L1_CHAIN_ID)}
              isWithdraw={true}
              onSwitchHandler={() => {
                reportEvent({
                  name: GA_MAP.withdrawPopupSwitch,
                  data: { name: 'Withdraw Switch Network' },
                });
              }}
            />
          ) : null}

          {!initiateHash &&
          isConnected &&
          currentChain &&
          currentChain.id !== Number(env.L2_CHAIN_ID) ? (
            <SwitchNetworkButton
              id={Number(env.L2_CHAIN_ID)}
              isWithdraw={true}
              onSwitchHandler={() => {
                reportEvent({
                  name: GA_MAP.withdrawPopupSwitch,
                  data: { name: 'Withdraw Switch Network' },
                });
              }}
            />
          ) : null}

          {/* BNB withdraw */}
          {isConnected &&
          currentChain &&
          currentChain.id === Number(env.L2_CHAIN_ID) &&
          tokenType === 'BNB' &&
          step < 1 &&
          !isConfirming ? (
            <WithdrawButton
              disabled={!isSufficient || !withdrawVal || !tickBoxOne}
              onClick={() => {
                reportEvent({
                  name: GA_MAP.withdrawInitClick,
                  data: { name: 'Click Init Withdrawal' },
                });
                try {
                  setIsConfirming(true);
                  withdraw(withdrawVal)
                    .then((res: any) => {
                      if (res) {
                        batchUpdate(() => {
                          setInitHash(res as string);
                          setIsConfirming(false);
                          // Load withdrawal history 10 mins later to check whether it is ready to prove withdraw
                          setTimeout(() => loadHistory(), 1000 * 60 * 10);
                        });
                      } else {
                        setIsConfirming(false);
                      }
                    })
                    .catch((e: any) => {
                      //eslint-disable-next-line no-console
                      console.log(e);
                      setIsConfirming(false);
                    });
                } catch (e) {
                  //eslint-disable-next-line no-console
                  console.log(e);
                  setIsConfirming(false);
                }
              }}
            >
              Initiate Withdrawal
            </WithdrawButton>
          ) : null}

          {isConnected &&
          currentChain &&
          currentChain.id === Number(env.L2_CHAIN_ID) &&
          tokenType !== 'BNB' &&
          step < 1 &&
          !isConfirming ? (
            <DepositButton
              w={'100%'}
              disabled={!tickBoxOne || !isSufficient || !withdrawVal}
              onClick={() => {
                reportEvent({
                  name: GA_MAP.withdrawInitClick,
                  data: { name: 'Click Init ERC20 WithDraw' },
                });
                setIsConfirming(true);
                if (asset && asset?.length > 0) {
                  withdrawERC20(withdrawVal, asset[0])
                    .then((res) => {
                      if (res) {
                        batchUpdate(() => {
                          setIsConfirming(false);
                          setInitHash(res);
                          // Load withdrawal history 10 mins later to check whether it is ready to prove withdraw
                          setTimeout(() => loadHistory(), 1000 * 60 * 10);
                        });
                      } else {
                        setIsConfirming(false);
                      }
                    })
                    .catch((e: any) => {
                      batchUpdate(() => {
                        if (e) {
                          setTxFailedMsgErc20(e?.code || e);
                        }
                        setIsConfirming(false);
                        handleErrorShowErc20(true);
                      });
                      // eslint-disable-next-line no-console
                      console.log(e);
                    });
                }
              }}
            >
              Initiate Withdrawal
            </DepositButton>
          ) : null}

          {isConfirming ? (
            <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
              <Box w={20} h={20} mr={8}>
                <Loading
                  style={{ height: '20px' }}
                  shortroundcolor="#E6E8EA"
                  longroundcolor="#76808F"
                />
              </Box>
              <Box h={20}>Confirm in Wallet</Box>
            </DepositButton>
          ) : historyCache && historyCache[initiateHash] && step !== 5 ? (
            // Waiting to prove or finalize
            <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
              <Box w={20} h={20} mr={8}>
                <Loading
                  style={{ height: '20px' }}
                  shortroundcolor="#E6E8EA"
                  longroundcolor="#76808F"
                />
              </Box>
              {step === 2 ? 'Proving Withdrawal' : 'Finalizing Withdrawal'}
            </DepositButton>
          ) : step === 1 || step === 3 ? (
            <>
              <Box w={'100%'} alignItems={'center'}>
                <WaitingText>{TxStatus[step]}</WaitingText>
              </Box>
              <Warning>
                <ColoredWarningIcon width={16} height={16} mr={4} color="scene.orange.normal" />
                <Box>
                  The withdrawal process depends on your manual{' '}
                  <span>{step === 1 ? 'Prove' : 'Finalize'}</span> operation. Please return to the{' '}
                  <PrimaryLink
                    onClick={() => {
                      reportEvent({
                        name: GA_MAP.withdrawPopupHistoryLink,
                        data: { name: 'Popup History Link' },
                      });
                    }}
                    href="/history?type=withdraw"
                  >
                    history
                  </PrimaryLink>{' '}
                  page to <span>{step === 1 ? `Prove Withdrawal` : `Finalize Withdrawal`}</span>{' '}
                  after{' '}
                  {step === 1
                    ? env.NET_ENV === 'Mainnet'
                      ? '1-2 hours'
                      : '15 mins'
                    : env.NET_ENV === 'Mainnet'
                    ? '7 days'
                    : '30 mins'}
                  .
                </Box>
              </Warning>
            </>
          ) : step === 5 ? (
            <WithdrawButton
              onClick={() => {
                router.push('/history?type=withdraw');
              }}
            >
              {TxStatus[step]}
            </WithdrawButton>
          ) : // Prove Button
          !isConfirming &&
            step === 2 &&
            currentChain &&
            currentChain.id === Number(env.L1_CHAIN_ID) ? (
            <WithdrawButton
              withdrawtype="prove"
              onClick={async () => {
                reportEvent({
                  name: GA_MAP.withdrawProveClick,
                  data: { name: 'Prove Withdraw' },
                });
                const status = await loadPausedStatus(false);
                setIsConfirming(true);
                try {
                  if (!status) {
                    proveWithdraw(initiateHash)
                      .then(async (res) => {
                        if (res) {
                          setProveHash(res);
                          if (typeof window !== 'undefined') {
                            // Add temp status into cache
                            await addDataIntoCache(window.location.hostname, {
                              [`${initiateHash}`]: step,
                            });
                          }
                          loadHistory();
                        }
                        setIsConfirming(false);
                      })
                      .catch((e: any) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        setIsConfirming(false);
                      });
                  } else {
                    setIsConfirming(false);
                  }
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.log(e);
                  setIsConfirming(false);
                }
              }}
            >
              {TxStatus[step]}
            </WithdrawButton>
          ) : // Finalize Button
          !isConfirming &&
            step === 4 &&
            currentChain &&
            currentChain.id === Number(env.L1_CHAIN_ID) ? (
            <WithdrawButton
              withdrawtype="finalize"
              onClick={async () => {
                reportEvent({
                  name: GA_MAP.withdrawFinalizeClick,
                  data: { name: 'Finalize Withdraw' },
                });
                const status = await loadPausedStatus(false);
                try {
                  if (!status) {
                    setIsConfirming(true);
                    finalWithdraw(initiateHash)
                      .then(async (res) => {
                        if (res) {
                          setFinalizeHash(res);
                          if (typeof window !== 'undefined') {
                            // Add temp status into cache
                            await addDataIntoCache(window.location.hostname, {
                              [`${initiateHash}`]: step,
                            });
                          }
                          loadHistory();
                        }
                        setIsConfirming(false);
                      })
                      .catch((e: any) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        setIsConfirming(false);
                      });
                  } else {
                    setIsConfirming(false);
                  }
                } catch (e) {
                  //eslint-disable-next-line no-console
                  console.log(e);
                  setIsConfirming(false);
                }
              }}
            >
              {TxStatus[step]}
            </WithdrawButton>
          ) : null}

          {txProveFailedMsg && (
            <TxError
              theme="light"
              title={`Prove Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                batchUpdate(() => {
                  setTxProveFailedMsg('');
                  setCurrentTx(null);
                  setIsConfirming(false);
                });
                // close withdraw popup
                handleOpen(false);
                handleErrorShow(false);
              }}
              errorCode={txProveFailedMsg}
            />
          )}

          {txFinalizeFailedMsg && (
            <TxError
              theme="light"
              title={`Finalize Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                batchUpdate(() => {
                  setTxFinalizeFailedMsg('');
                  setCurrentTx(null);
                  setIsConfirming(false);
                });
                // close withdraw popup
                handleOpen(false);
                handleErrorShow(false);
              }}
              errorCode={txFinalizeFailedMsg}
            />
          )}
        </Box>
      </Flex>
    </Container>
  );
};

export const PrimaryLink = styled(Link)`
  color: ${(props: any) => props.theme.colors?.readable?.link};
  font-size: 14px;
  line-height: 18px;
  &:hover {
    color: ${(props: any) => props.theme.colors?.scene.primary.normal};
  }
`;

export const Warning = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props: any) => props.theme.colors.readable.pageButton};
  span {
    font-weight: 700;
  }
`;

export const WaitingText = styled.div`
  line-height: 20px;
  text-align: center;
  padding: 16px 24px;
  background: #fbe0c6;
  font-weight: 500;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors?.scene?.orange.waiting};
`;

const StyledCheckbox = styled(Checkbox)`
  .ui-checkbox-label {
    font-size: 12px;
    color: ${(props: any) => props.theme.colors?.readable.normal};
    font-weight: 500;
  }
  &.ui-checkbox {
    align-items: flex-start;
  }
  .ui-checkbox-control {
    width: 18px;
    flex: 1 0 18px;
    &[data-checked] {
      border: none;
      border-color: ${(props: any) => props.theme.colors?.scene.primary.active};
      svg path {
        stroke: ${(props: any) => props.theme.colors?.readable.white};
        fill: ${(props: any) => props.theme.colors?.readable.white};
      }
    }
    &:hover {
      border-color: ${(props: any) => props.theme.colors?.scene.primary.active};
    }
  }
  svg {
    width: 18px;
  }
  span {
    font-weight: 700;
  }
`;

export const Container = styled(Modal)`
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 542px;
    background: ${(props: any) => props.theme.colors.bg.card};
    color: ${(props: any) => props.theme.colors.readable?.normal};
    ${mobileMedia} {
      margin: 0px;
      padding: 24px;
      max-width: 542px !important;
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  ${mobileMedia} {
    top: auto !important;
    border-radius: 0px;
    &::after {
      height: 0px;
    }
  }
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
  ${mobileMedia} {
    margin-bottom: 16px;
  }
`;

export const WithdrawButton = styled(Button)`
  line-height: 20px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors.bg?.card};
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.secondary};
      background: ${(props: any) => props.theme.colors.readable.disabled};
      opacity: 1;
    }
  }
`;

import { Icon, IconProps } from '@totejs/icons';

export const ETH = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="10" fill="#ECEFF0" />
      <path
        opacity="0.6"
        d="M9.9977 7.9187L5.09009 10.1478L9.9977 13.051L14.9112 10.1478L9.9977 7.9187Z"
        fill="black"
      />
      <path
        opacity="0.45"
        d="M5.09009 10.1478L9.9977 13.051V7.9187V2L5.09009 10.1478Z"
        fill="black"
      />
      <path opacity="0.8" d="M9.9978 2V7.9187V13.051L14.9113 10.1478L9.9978 2Z" fill="black" />
      <path opacity="0.45" d="M5.09009 11.082L9.9977 18V13.9793L5.09009 11.082Z" fill="black" />
      <path opacity="0.8" d="M9.9978 13.9793V18L14.9113 11.082L9.9978 13.9793Z" fill="black" />
    </Icon>
  );
};

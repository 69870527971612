import { useAccount, useBalance } from 'wagmi';
import styled from '@emotion/styled';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Box, Flex } from '@totejs/uikit';
import { TokenObjectType, mobileMedia } from '@op-bridge/bridge-core';

import * as env from '../env';
import { removeTrailingZero, getEtherFormatValue, batchUpdate } from '../utils';
import { Loading } from './Loading';

interface TokenBalanceProp {
  tokenName: string;
  type: string;
  loadingComponent?: React.ReactNode;
  l1ChainId: string;
  l2ChainId: string;
  l1Rpc: string;
  l2Rpc: string;
  assetList: TokenObjectType[];
  isMenuOpen: boolean;
}
const l1RpcProvider = new ethers.providers.JsonRpcProvider(env.L1_RPC_URL, 'any');
const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');

export const TokenBalance = (props: TokenBalanceProp) => {
  const { tokenName, type, loadingComponent, l1ChainId, l2ChainId, assetList, isMenuOpen } = props;
  const { isConnected, address } = useAccount();

  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [isLoadingBnbBalance, setIsLoadingBnbBalance] = useState(false);
  const { data: tokenBalance, isLoading: tokenBalanceLoading } = useBalance({
    address: address,
    chainId: type === 'deposit' ? Number(l1ChainId) : Number(l2ChainId),
  });

  const [balance, setBalance] = useState('');

  /**
   * Get ERC20 token balance by user account address
   */
  const getErc20Balance = useCallback(async () => {
    const filteredAsset = assetList?.filter((asset: any) => asset.symbol === tokenName);
    if (Array.isArray(filteredAsset) && filteredAsset?.length > 0 && address) {
      const asset = filteredAsset[0];

      // use JsonRPCProvider to prevent getting error without switching to correct network
      if (type === 'deposit') {
        const l1Signer = l1RpcProvider.getSigner(address);
        const l1ERC20 = new ethers.Contract(asset.l1Address, asset.ABI, l1Signer);
        const l1BalanceObj = await l1ERC20.balanceOf(address);
        const balance = await getEtherFormatValue(l1BalanceObj);
        return balance;
      } else {
        const l2Signer = l2RpcProvider.getSigner(address);
        const l2ERC20 = new ethers.Contract(asset.l2Address, asset.ABI, l2Signer);
        const l2BalanceObj = await l2ERC20.balanceOf(address);
        const balance = await getEtherFormatValue(l2BalanceObj);
        return balance;
      }
    }

    return null;
  }, [address, tokenName, type, assetList]); // end getErc20Balance

  useEffect(() => {
    if (address) {
      try {
        if (tokenName !== 'BNB' && isMenuOpen) {
          setIsLoadingBalance(true);
          getErc20Balance()
            .then((balance) => {
              // only update when menu is opened
              if (balance && isMenuOpen) {
                batchUpdate(() => {
                  setIsLoadingBalance(false);
                  setBalance(balance.toString());
                });
              } else {
                setIsLoadingBalance(false);
              }
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log(e);
              setIsLoadingBalance(false);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, tokenName, type, isMenuOpen]);

  useEffect(() => {
    if (address) {
      try {
        if (tokenName === 'BNB') {
          setIsLoadingBnbBalance(true);
          if (tokenBalance && !tokenBalanceLoading) {
            setBalance(tokenBalance.formatted);
          }
          setIsLoadingBnbBalance(false);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        setIsLoadingBnbBalance(false);
      }
    }
  }, [tokenBalance, tokenBalanceLoading, address, tokenName, type]);

  return isConnected ? (
    !isLoadingBalance && !isLoadingBnbBalance ? (
      <Amount>{removeTrailingZero(Number(balance).toFixed(8))}</Amount>
    ) : (
      <Flex justifyContent={'flex-end'} alignItems={'center'} minWidth={125}>
        <Box width={20} height={20}>
          {loadingComponent ? loadingComponent : <Loading />}
        </Box>
      </Flex>
    )
  ) : null;
};

const Amount = styled.div`
  width: 125px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.readable.disabled};
  text-align: right;
  ${mobileMedia} {
    width: 154px;
  }
`;

import { useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useAccount, useNetwork } from 'wagmi';

import { getEtherFormatValue } from '../utils';
import { useGetCrossChainMessenger } from './useGetCrossChainMessenger';
import { useL1Withdraw } from './useL1Withdraw';
import * as env from '../env';

export const useGetGasPrice = () => {
  const { crossChainMessenger } = useGetCrossChainMessenger();
  const { crossChainMessenger: c1 } = useL1Withdraw();
  const { chain: currentChain } = useNetwork();
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isLoadingGasPrice, setIsLoadingGasPrice] = useState(false);

  const getEstimateGas = async (tokeNumber: string, type: string) => {
    if (crossChainMessenger) {
      setLoading(true);
      let estiGas;
      try {
        if (type === 'deposit' && currentChain && currentChain.id === Number(env.L1_CHAIN_ID)) {
          estiGas = await crossChainMessenger.estimateGas.depositETH(
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { l2GasLimit: 1 },
          );
        } else if (currentChain && currentChain.id === Number(env.L2_CHAIN_ID)) {
          estiGas = await crossChainMessenger.estimateGas.withdrawETH(
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { overrides: { from: address } },
          );
        }
        //eslint-disable-next-line no-console
        // console.log('BNB input', tokeNumber, 'output', Number(estiGas));
        setLoading(false);
        if (estiGas) {
          return { val: await getEtherFormatValue(estiGas), error: false, msg: '' };
        } else {
          const bn0 = BigNumber.from('0');
          // return getEtherFormatValue(bn0);
          return {
            val: getEtherFormatValue(bn0),
            error: true,
            msg: 'Unable to get estimated gas from op SDK',
          };
        }
      } catch (e: any) {
        //eslint-disable-next-line no-console
        console.log(e);
        setLoading(false);
        const bn0 = BigNumber.from('0');
        return { val: await getEtherFormatValue(bn0), error: true, msg: e?.code };
      }
    }
  };

  const getEstimateApproveGas = async (
    l1Address: string,
    l2Address: string,
    tokeNumber: string,
  ) => {
    if (c1 && l1Address && l2Address && address) {
      // approve ERC20 deposit estimated gas
      setLoading(true);
      try {
        // eslint-disable-next-line no-console
        // console.log('approve', l1Address, l2Address, tokeNumber, address);
        const appEstiGas = await c1.estimateGas.approveERC20(
          l1Address,
          l2Address,
          ethers.utils.parseUnits(tokeNumber, 'ether'),
          { overrides: { from: address } },
        );
        setLoading(false);
        // eslint-disable-next-line no-console
        // console.log('approve estimate gas sdk output ', appEstiGas);
        return { val: await getEtherFormatValue(appEstiGas), error: false, msg: '' };
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        setLoading(false);
        const bn = BigNumber.from('0');
        return { val: await getEtherFormatValue(bn), error: true, msg: e?.code };
      }
    }
  };

  const getEstimateERC20Gas = async (
    l1Address: string,
    l2Address: string,
    tokeNumber: string,
    type: string,
  ) => {
    if (c1 && l1Address && l2Address && address) {
      setLoading(true);
      let estiGas;
      try {
        if (type === 'deposit') {
          // deposit ERC20 estimated gas
          // eslint-disable-next-line no-console
          // console.log('deposit', l1Address, l2Address, tokeNumber, address);
          estiGas = await c1.estimateGas.depositERC20(
            l1Address,
            l2Address,
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { overrides: { from: address }, l2GasLimit: 1 },
          );
        } else {
          // eslint-disable-next-line no-console
          // console.log('withdraw', l1Address, l2Address, tokeNumber, address);
          estiGas = await c1.estimateGas.withdrawERC20(
            l1Address,
            l2Address,
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { overrides: { from: address } },
          );
        }
        setLoading(false);
        //eslint-disable-next-line no-console
        // console.log('BEP20 tokeNumber estimate gas output', Number(estiGas));
        return { val: await getEtherFormatValue(estiGas), error: false, msg: '' };
      } catch (e: any) {
        //eslint-disable-next-line no-console
        console.log(e);
        setLoading(false);
        const bn = BigNumber.from('0');
        return { val: await getEtherFormatValue(bn), error: true, msg: e.message };
      }
    }
  };

  const getGasPrice = async (chainRpc: string) => {
    const l1provider = new ethers.providers.JsonRpcProvider(chainRpc);
    setIsLoadingGasPrice(true);
    try {
      const gasPriceBignumber = await l1provider.getGasPrice();
      const gasPrice = await getEtherFormatValue(gasPriceBignumber);
      setIsLoadingGasPrice(false);
      return gasPrice;
    } catch (e: any) {
      return { val: 0, error: true, msg: e };
    }
  };

  return {
    getEstimateGas,
    getEstimateERC20Gas,
    getEstimateApproveGas,
    isLoadingPrice: loading, // Estimated gas price loading
    getGasPrice,
    isLoadingGasPrice, // L1 or L2 gas price loading
  };
};

import { Icon, IconProps } from '@totejs/icons';

export const ProgressSuccessIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32.876 16C32.876 24.8366 25.7125 32 16.876 32C8.03942 32 0.875977 24.8366 0.875977 16C0.875977 7.16344 8.03942 0 16.876 0C25.7125 0 32.876 7.16344 32.876 16Z"
        fill="#02C076"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.174 9.5322C25.881 10.0035 26.0469 10.9719 25.5371 11.6517L16.3849 23.8546C16.0138 24.3493 15.2872 24.3921 14.8607 23.9443L8.67668 17.451C8.11999 16.8665 8.13123 15.9446 8.702 15.3738C9.25148 14.8243 10.1314 14.7906 10.7214 15.2963L15.2758 19.2L23.2232 9.80765C23.709 9.23349 24.5482 9.115 25.174 9.5322Z"
        fill="white"
      />
    </Icon>
  );
};

import { providers } from 'ethers';
import { useMemo } from 'react';
import { useAccount, useSigner } from 'wagmi';

export function walletClientToSigner(walletClient: any, address: string) {
  if (!walletClient) return null;
  const provider = new providers.Web3Provider(walletClient?.provider?.provider);
  const signer = provider.getSigner(address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { address } = useAccount();
  const { data: walletClient } = useSigner({ chainId });
  return useMemo(
    () => (walletClient && address ? walletClientToSigner(walletClient, address) : undefined),
    [walletClient, address],
  );
}

// export const useGetSigners = () => {
//   const getSigners = useCallback((address: string) => {
//     // let l1RpcProvider = null;
//     // const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');
//     // if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
//     //   l1RpcProvider = new ethers.providers.Web3Provider(window.ethereum as any);
//     //   if (connector?.name?.toLocaleLowerCase() === 'trust wallet') {
//     //     l1RpcProvider = new ethers.providers.Web3Provider(window?.trustWallet as any);
//     //   }
//     return [signer1, signer2];
//     // }
//     // return [];
//   }, []);
//   return { getSigners };
// };

import { Icon, IconProps } from '@totejs/icons';

export const BUSD = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="10" fill="#F0B90B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74406 7.45997L10.0041 3.19995L11.7027 4.89854L7.44265 9.15856L5.74406 7.45997ZM5.74716 12.5505L12.5551 5.74264L14.2537 7.44123L7.44575 14.2491L5.74716 12.5505ZM15.1012 8.2936L8.29331 15.1015L9.99189 16.8001L16.7998 9.99219L15.1012 8.2936ZM3.19995 10.0035L4.89854 8.30496L6.59713 10.0035L4.89854 11.7021L3.19995 10.0035Z"
        fill="white"
      />
    </Icon>
  );
};

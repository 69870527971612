import * as ethers from 'ethers';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';
import { useResponsive, usePending, useTxFailed } from '@op-bridge/bridge-core';

import { useGetCrossChainMessenger } from './useGetCrossChainMessenger';
import * as env from '../env';
import { batchUpdate, getEtherFormatValue } from '../utils';

const l1RPC = env.L1_RPC_URL;

const l1RpcProvider = new ethers.providers.JsonRpcProvider(l1RPC);
// const l2RpcProvider = new ethers.providers.JsonRpcProvider(l2RPC);

export const useDeposit = () => {
  const [isSufficient, setIsSufficient] = useState(false);
  const [txFailedMsg, setTxFailedMsg] = useState('');
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [address, setAddress] = useState<null | string>(null);
  const { isMobile } = useResponsive();

  const { showPending, handlePendingShow, handlePendingToggle } = usePending();
  const { showError, handleErrorShow, handleErrorToggle } = useTxFailed();

  const { address: wagmiAddress } = useAccount();
  const { crossChainMessenger } = useGetCrossChainMessenger();

  useEffect(() => {
    let mount = true;
    if (wagmiAddress && mount) {
      setAddress(wagmiAddress);
    }
    return () => {
      mount = false;
    };
  }, [wagmiAddress]);

  // check account balance
  useEffect(() => {
    let mount = true;
    if (address) {
      reportBalances().then((balance) => {
        if (balance && mount) {
          setIsSufficient(true);
        }
      });
    }
    return () => {
      setIsSufficient(false);
      mount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const reportBalances = async () => {
    if (address) {
      try {
        const balanceObj = await l1RpcProvider.getBalance(address);
        const balance = await getEtherFormatValue(balanceObj);
        if (balance) {
          return balance;
        } else {
          return null;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return null;
      }
    }
  };

  const deposit = async (depositAmount: string, gasLimit?: number) => {
    const balance = await reportBalances();
    if (!balance) {
      setIsSufficient(false);
      return;
    }

    if (crossChainMessenger && depositAmount) {
      try {
        // show pending modal
        handlePendingShow(true);
        setWaitingConfirmation(true);

        // start deposit
        const response = await crossChainMessenger.depositETH(
          ethers.utils.parseUnits(depositAmount, 'ether'),
          { overrides: { gasLimit: gasLimit }, l2GasLimit: 1 },
        );
        await response.wait();
        // end deposit

        setWaitingConfirmation(false);
        handlePendingShow(false);

        return response.hash;
      } catch (e: any) {
        setWaitingConfirmation(false);
        handlePendingShow(false);

        if (e && e?.code) {
          batchUpdate(() => {
            try {
              // do not show error if user accelerate tx (tx hash replaced)
              if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                // eslint-disable-next-line no-console
                console.log(e.replacement);
                return e.replacement.hash;
              } else {
                const errorMsg = isMobile ? e?.message || e?.code : e?.code;
                setTxFailedMsg(errorMsg);
              }
              // eslint-disable-next-line no-console
              console.log(e?.code);
            } catch (e) {}
            handleErrorShow(true);
          });
        } else {
          setTxFailedMsg(e || 'Transaction Failed');
        }
        // eslint-disable-next-line no-console
        console.log('deposit error', e);
        return null;
      }
    }
    return null;
  };

  return {
    reportBalances,
    deposit,
    isSufficient,
    waitingConfirmation,
    showPending,
    handlePendingShow,
    handlePendingToggle,
    txFailedMsg,
    showError,
    handleErrorShow,
    handleErrorToggle,
  };
};

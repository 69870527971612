import { NET_ENV } from '../env';
import { BNB, USDT, BTC, BUSD, DAI, USDC, ETH, COMBO } from './svgIcon/token';

export let menus =
  NET_ENV === 'Testnet'
    ? [
        { name: 'BTCB', icon: <BTC width={20} height={20} /> },
        { name: 'BTC', icon: <BTC width={20} height={20} /> },
        { name: 'ETH', icon: <ETH width={20} height={20} /> },
        { name: 'BNB', icon: <BNB width={20} height={20} /> },
        { name: 'USDT', icon: <USDT width={20} height={20} /> },
        { name: 'BUSD', icon: <BUSD width={20} height={20} /> },
        { name: 'USDC', icon: <USDC width={20} height={20} /> },
        { name: 'DAI', icon: <DAI width={20} height={20} /> },
      ]
    : [
        { name: 'BNB', icon: <BNB width={20} height={20} /> },
        { name: 'COMBO', icon: <COMBO width={20} height={20} /> },
        { name: 'BTCB', icon: <BTC width={20} height={20} /> },
        { name: 'ETH', icon: <ETH width={20} height={20} /> },
        { name: 'BUSD', icon: <BUSD width={20} height={20} /> },
        { name: 'USDC', icon: <USDC width={20} height={20} /> },
        { name: 'DAI', icon: <DAI width={20} height={20} /> },
        { name: 'USDT', icon: <USDT width={20} height={20} /> },
      ];

export const Menu = () => {
  return null;
};

import styled from '@emotion/styled';
import { WalletKitButton } from '@totejs/walletkit';
import { GA_MAP, reportEvent } from '@op-bridge/bridge-core';
import { Button } from '@totejs/uikit';

export const ConnectButton = ({ type }: { type: string }) => {
  return (
    <WalletKitButton.Custom>
      {({ show, isConnected }) => {
        return !isConnected ? (
          <StyledButton
            onClick={() => {
              show();
              try {
                if (type === 'deposit') {
                  reportEvent({
                    name: GA_MAP.depositConnectButton,
                    data: {
                      name: 'Connect wallet',
                    },
                  });
                } else {
                  reportEvent({
                    name: GA_MAP.withdrawConnectButton,
                    data: { name: 'Connect Wallet' },
                  });
                }
              } catch (e: any) {
                // eslint-disable-next-line no-console
                console.log(e);
              }
            }}
          >
            Connect Wallet
          </StyledButton>
        ) : null;
      }}
    </WalletKitButton.Custom>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  color: ${(props: any) => props.theme.colors.bg.card};
`;

import { Icon, IconProps } from '@totejs/icons';

export const PolyhedraIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" {...props}>
      <rect width="40" height="40" rx="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2503 5.14442L20 5L19.7497 5.14442L7.25033 12.3556L7 12.5V12.7888V27.2112V27.5L7.25033 27.6445L19.7497 34.8555L20 35L20.2503 34.8555L32.7496 27.6445L33 27.5V27.2112V12.7888V12.5L32.7496 12.3556L20.2503 5.14442ZM8.00131 25.6652V13.0777L20 6.15537L31.9987 13.0777V25.6652L20.3904 11.2137L20 10.7277L19.6096 11.2137L8.00131 25.6652ZM8.52143 27.2224L19.6434 33.6389V24.3286C15.8138 24.385 11.9946 25.3495 8.52143 27.2224ZM9.21838 25.7485L19.6434 12.77V23.3279C16.0767 23.3783 12.5184 24.1852 9.21838 25.7485ZM20.6447 24.3345V33.4726L31.4786 27.2224C28.0925 25.3966 24.3776 24.4339 20.6447 24.3345ZM30.7815 25.7485C27.5704 24.2273 24.1147 23.4223 20.6447 23.3336V13.1288L30.7815 25.7485Z"
        fill="url(#paint0_linear_2611_90055)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2611_90055"
          x1="7.01849"
          y1="27.7884"
          x2="20.8475"
          y2="5.44389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CFFF0" />
          <stop offset="1" stopColor="#0CFFA7" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

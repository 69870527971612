import { Icon, IconProps } from '@totejs/icons';

export const DAI = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="10" fill="#F4B731" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.60982 4.375H10.1554C12.9204 4.375 15.0162 5.86114 15.7959 8.02365H17.2119V9.33071H16.0941C16.116 9.53727 16.1272 9.74768 16.1272 9.96148V9.99358C16.1272 10.2343 16.113 10.4709 16.0854 10.7026H17.2119V12.0097H15.769C14.9687 14.1421 12.8891 15.6141 10.1554 15.6141H5.60982V12.0097H4.03027V10.7026H5.60982V9.33071H4.03027V8.02365H5.60982V4.375ZM6.88059 12.0097V14.4416H10.1554C12.1763 14.4416 13.6777 13.4676 14.3766 12.0097H6.88059ZM14.7659 10.7026H6.88059V9.33071H14.7679C14.7972 9.54662 14.8121 9.76793 14.8121 9.99358V10.0257C14.8121 10.2565 14.7965 10.4825 14.7659 10.7026ZM10.1554 5.54559C12.1856 5.54559 13.6915 6.54528 14.3862 8.02365H6.88059V5.54559H10.1554Z"
        fill="white"
      />
    </Icon>
  );
};

import { useState } from 'react';

export const useInputValidator = (curr: number, min?: number, max?: number, eql?: number) => {
  const [status, setStatus] = useState(0);
  let _min = min || 0;
  let _max = max || 0;
  let _eql = eql || 0;
  const changeVal = (curr: number) => {
    let tmp = 0;
    if (curr === _eql) {
      tmp = 1;
    } else if (curr > _max) {
      tmp = 2;
    } else if (curr < _min) {
      tmp = 3;
    }
    setStatus(tmp);
    return tmp;
  };
  const setMax = (max: number) => {
    _max = max;
  };
  const setMin = (min: number) => {
    _min = min;
  };
  const setEql = (eql: number) => {
    _eql = eql;
  };
  return { status, changeVal, setMax, setMin, setEql };
};

import { Icon, IconProps } from '@totejs/icons';

export const BTC = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.7023 12.4164C18.3722 17.7811 12.9333 21.0325 7.58312 19.7024C2.23293 18.3722 -1.03336 12.9336 0.296802 7.58365C1.62696 2.21891 7.05105 -1.03245 12.416 0.297649C17.781 1.64253 21.0325 7.06639 19.7023 12.4164Z"
        fill="#F6921A"
      />
      <path
        d="M14.3329 8.51353C14.525 7.18343 13.52 6.47404 12.1307 5.98633L12.5741 4.18331L11.4804 3.90251L11.037 5.6612C10.7414 5.58731 10.4458 5.52819 10.165 5.45429L10.6084 3.68082L9.51473 3.40002L9.07134 5.20305C8.83487 5.14394 8.5984 5.0996 8.37671 5.04048L6.86918 4.65623L6.57359 5.82376C6.57359 5.82376 7.38647 6.01589 7.37169 6.01589C7.81508 6.13412 7.90376 6.41492 7.88898 6.65138L7.37169 8.70565C7.40125 8.72043 7.4456 8.72043 7.48994 8.73521C7.46038 8.72043 7.41603 8.72043 7.37169 8.70565L6.64749 11.5875C6.58837 11.7205 6.45537 11.9275 6.145 11.8536C6.15978 11.8683 5.3469 11.6614 5.3469 11.6614L4.80005 12.9176L6.23367 13.2723C6.49971 13.3462 6.76573 13.4053 7.01698 13.4792L6.55882 15.297L7.65251 15.5778L8.09589 13.7748C8.39148 13.8635 8.68708 13.9374 8.96789 13.9965L8.5245 15.7848L9.61819 16.0655L10.0764 14.2477C11.9534 14.6024 13.3574 14.4546 13.9486 12.7699C14.4215 11.4102 13.919 10.6269 12.9436 10.1096C13.653 9.96186 14.1851 9.48893 14.3329 8.51353ZM11.8203 12.0309C11.4804 13.3906 9.18959 12.6516 8.43583 12.4743L9.04179 10.0653C9.79554 10.2427 12.175 10.6121 11.8203 12.0309ZM12.1603 8.49875C11.8499 9.74018 9.94334 9.10468 9.3226 8.95689L9.86944 6.76962C10.4902 6.91741 12.4854 7.1982 12.1603 8.49875Z"
        fill="white"
      />
    </Icon>
  );
};

import { StateModal, Button, Flex } from '@totejs/uikit';
import styled from '@emotion/styled';
import { mobileMedia, GA_MAP, reportEvent } from '@op-bridge/bridge-core';
import { useRouter } from 'next/router';

import { BSC_SCAN, NET_ENV } from '../../env';

interface SuccessfulProps {
  isOpen: boolean;
  handleOpen: (show: boolean) => void;
  txHash: string;
  clearInput: () => void;
}
export const DepositSuccessful = (props: SuccessfulProps) => {
  const { isOpen, handleOpen, txHash, clearInput } = props;
  const router = useRouter() as any;

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        reportEvent({
          name: GA_MAP.depositPPClose,
          data: { name: 'Close Popup' },
        });
        handleOpen(false);
        clearInput();
      }}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
      margin={0}
      overflow={'hidden'}
      title="Transaction Submitted"
      description="Deposits take about 1 minute before they are considered finalized."
    >
      <Flex flexDirection={'column'} flex={1}>
        <ViewTxButton
          marginBottom={[16, 24, 24]}
          onClick={() => {
            reportEvent({
              name: GA_MAP.depositHistory,
              data: { name: 'View Deposit History' },
            });
            router.push('/history?type=deposit');
          }}
        >
          View in History
        </ViewTxButton>
        <ViewTxButton
          marginBottom={[16, 24, 24]}
          onClick={() => {
            reportEvent({ name: GA_MAP.viewl1Click, data: { name: 'View BSC Scan' } });
            if (window) {
              window.open(`${BSC_SCAN[NET_ENV]}/tx/${txHash}`);
            }
          }}
        >
          View in BscScan
        </ViewTxButton>
        <AgainButton
          onClick={() => {
            reportEvent({
              name: GA_MAP.depositAgain,
              data: {
                name: 'Try Again',
              },
            });
            clearInput();
            handleOpen(false);
          }}
        >
          Deposit Again
        </AgainButton>
      </Flex>
    </Container>
  );
};

const Container = styled(StateModal)`
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 568px;
    background: ${(props: any) => props.theme.colors.bg.card};
    color: ${(props: any) => props.theme.colors.bg?.card};
    ${mobileMedia} {
      margin: 0 16px;
      width: 343px;
    }
  }
  .ui-modal-body {
    font-size: 18px;
    color: ${(props: any) => props.theme.colors.readable?.secondary};
    font-weight: 400;
  }
  .ui-state-modal-title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 16px;
    ${mobileMedia} {
      margin-bottom: 8px;
    }
  }
  .ui-close-button {
    color: ${(props: any) => props.theme.colors?.readable.secondary};
  }
`;

export const ViewTxButton = styled(Button)`
  width: 100%;
  height: 48px;
  background: ${(props: any) => props.theme.colors.readable?.white};
  color: ${(props: any) => props.theme.colors?.readable.normal};
  border: 1px solid ${(props: any) => props.theme.colors?.readable.normal};
  &:hover {
    background: ${(props: any) => props.theme.colors?.readable.secondary};
    border: 1px solid ${(props: any) => props.theme.colors?.readable.secondary};
    color: ${(props: any) => props.theme.colors.readable.white};
  }
`;

export const AgainButton = styled(Button)`
  width: 100%;
  height: 48px;
  color: ${(props: any) => props.theme.colors?.bg.card};
`;

import { BigNumber, ethers } from 'ethers';
import * as optimismSDK from '@eth-optimism/sdk';
import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';

import { L2_RPC_URL } from '../env';
import greeterJSON from '../base/Greeter.json';

const greeterAddrs = {
  Mainnet: '0xcf210488dad6da5fe54d260c45253afc3a9e708c',
  Testnet: '0x106941459a8768f5a92b770e280555faf817576f',
};

export const useSDKGetL1DataGas = () => {
  const { address: wagmiAddress } = useAccount();
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  const getL2Signer = (address: string) => {
    const l2RpcProvider = new ethers.providers.JsonRpcProvider(L2_RPC_URL, 'any');
    return l2RpcProvider.getSigner(address);
  };

  const getSDKL1DataFee = async () => {
    const signer = await getL2Signer(address);
    if (address && signer) {
      // const signer = signer2;
      const Greeter = await new ethers.ContractFactory(
        greeterJSON.abi,
        greeterJSON.bytecode,
        signer,
      );
      const greeter = Greeter.attach(greeterAddrs.Testnet);
      const greeting = 'Hello!';

      const fakeTxReq = await greeter.populateTransaction.setGreeting(greeting);
      const fakeTx = await signer.populateTransaction(fakeTxReq);
      // console.log('Fake tx', fakeTx);

      let estimated = await getEstimates(signer.provider, fakeTx);
      const l2Gas = await greeter.estimateGas.setGreeting(greeting);
      estimated = { ...estimated, l2Gas: l2Gas };

      // eslint-disable-next-line no-console
      console.log(estimated);
    }
  };

  const getEstimates = async (provider: any, tx: any) => {
    return {
      totalCost: await optimismSDK.estimateTotalGasCost(provider, tx),
      l1Cost: await optimismSDK.estimateL1GasCost(provider, tx), // Estimates the amount of L1 gas cost for a given L2 tx in wei.
      l2Cost: await optimismSDK.estimateL2GasCost(provider, tx),
      l1Gas: await optimismSDK.estimateL1Gas(provider, tx),
      l2Gas: BigNumber.from('0'),
    };
  };

  return {
    getSDKL1DataFee,
  };
};

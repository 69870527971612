import styled from '@emotion/styled';
import { Box, Flex, Button } from '@totejs/uikit';
import { ExternalLinkIcon } from '@totejs/icons';
import { GA_MAP, reportEvent } from '@op-bridge/bridge-core';

import { PolyhedraIcon } from './svgIcon/PolyhedraIcon';
export default function ThirdPartyBridge({ type }: { type?: string }) {
  return (
    <Box mt={type !== 'withdraw' ? [40, 32] : [30]}>
      {type !== 'withdraw' && (
        <TitleContainer>
          <TitleLine />
          <TitleText>Third Party Bridge</TitleText>
        </TitleContainer>
      )}
      <StyledButton
        onClick={() => {
          reportEvent({ name: GA_MAP.thirdPartyClick, data: { name: 'third party click' } });
          if (window) {
            window.open(`https://zkbridge.com/combo`);
          }
        }}
      >
        <Flex flex={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Flex flexDirection={'row'}>
            <PolyhedraIcon width={40} height={40} mr={8} />
            <Box textAlign={'left'}>
              <Title>zkBridge</Title>
              <SubTitle>Trustless and efficient cross-chain bridge with zkSNARKs.</SubTitle>
            </Box>
          </Flex>
          <ExternalLinkIcon width={16} height={16} />
        </Flex>
      </StyledButton>

      <BottomText>
        These are independent service providers that COMBO is linking to for your convenience. COMBO
        has no responsibility for their operation.
      </BottomText>
    </Box>
  );
}

const TitleContainer = styled(Flex)`
  height: 15px;
  position: relative;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleLine = styled(Box)`
  height: 1px;
  width: 100%;
  background: ${(props: any) => props.theme.colors.readable?.border};
`;

const TitleText = styled(Box)`
  position: absolute;
  width: 159px;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: ${(props: any) => props.theme.colors.bg?.card};
  color: ${(props: any) => props.theme.colors.readable?.secondary};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 2px;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const StyledButton = styled(Button)`
  padding: 16px;
  width: 100%;
  height: 72px;
  background: ${(props: any) => props.theme.colors.bg.bottom};
  color: ${(props: any) => props.theme.colors.readable.secondary};
  border: 1px solid ${(props: any) => props.theme.colors.bg.status};
  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
    border: 1px solid ${(props: any) => props.theme.colors.scene.primary.normal};
    background: ${(props: any) => props.theme.colors.scene.primary.primaryOpacity};
  }
`;

const BottomText = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.readable.disabled};
  text-align: center;
`;

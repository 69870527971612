import * as optimismSDK from '@eth-optimism/sdk';
import { useEffect, useState } from 'react';
import { useSigner } from 'wagmi';

import * as env from '../env';
import { contracts } from '../base/sdkContract';

const predeploys = require('@eth-optimism/contracts');

const l1ChainId = Number(env.L1_CHAIN_ID);
const l2ChainId = Number(env.L2_CHAIN_ID);
const selfL1StandardBridge = env.L1StandardBridge;

export const useGetCrossChainMessenger = () => {
  const [crossChainMessenger, setCrossChainMessenger] =
    useState<optimismSDK.CrossChainMessenger | null>(null);

  const { data: signer1 } = useSigner({
    chainId: l1ChainId,
  });
  const { data: signer2 } = useSigner({
    chainId: l2ChainId,
  });

  useEffect(() => {
    if (signer1 && signer2) {
      try {
        const messenger = new optimismSDK.CrossChainMessenger({
          l1ChainId: l1ChainId,
          l2ChainId: l2ChainId,
          l1SignerOrProvider: signer1,
          l2SignerOrProvider: signer2,
          bedrock: true,
          contracts: contracts,
        });
        const bridgess = optimismSDK.getBridgeAdapters(l2ChainId, messenger, {
          overrides: {
            Standard: {
              Adapter: optimismSDK.StandardBridgeAdapter,
              l1Bridge: selfL1StandardBridge,
              l2Bridge: predeploys.predeploys.L2StandardBridge,
            },
            ETH: {
              Adapter: optimismSDK.ETHBridgeAdapter,
              l1Bridge: selfL1StandardBridge,
              l2Bridge: predeploys.predeploys.L2StandardBridge,
            },
          },
        });

        messenger.bridges = bridgess;
        setCrossChainMessenger(messenger);
      } catch (e) {
        //eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, [signer1, signer2]);

  return { crossChainMessenger, signer1, signer2 };
};

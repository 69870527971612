import styled from '@emotion/styled';
import { Box, Flex, keyframes } from '@totejs/uikit';
import { mobileMedia, GA_MAP, reportEvent } from '@op-bridge/bridge-core';
import { ExternalLinkIcon, TimeIcon, IconProps, Icon } from '@totejs/icons';
import { useCallback, useEffect, useState } from 'react';

import * as env from '../../env';
import { GasIcon } from '../svgIcon/GasIcon';
import { ProgressSuccessIcon } from '../svgIcon/ProgressSuccess';
import { SpinImage } from '../Loading';
import { useGetGasPrice } from '../../hooks';
import { ProgressTick } from '../svgIcon/ProgressTick';

export const TxLoading = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.8295 1 8.91925 1 8C1 7.08074 1.18106 6.17049 1.53284 5.32121C1.88463 4.47193 2.40024 3.70026 3.05025 3.05025C3.70027 2.40024 4.47194 1.88462 5.32122 1.53284C6.1705 1.18106 7.08075 1 8 1C8.91926 1 9.82951 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9498 3.05026C13.5998 3.70027 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8L15 8Z"
        stroke="#F7F7F7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1C8.91925 1 9.82951 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8"
        stroke="#8C8D8F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
interface ProgressProps {
  step: number;
  initiateHash?: string;
  proveHash?: string;
  finalizeHash?: string;
  estimateGas?: number;
  isLoadingL1GasPrice?: boolean;
  l1GasPrice: number;
  l2GasPrice?: number;
  bnbPrice: number;
  l1DataGas?: number;
  historyCache?: any;
}
export const WithdrawProgress = (props: ProgressProps) => {
  const {
    step,
    initiateHash,
    proveHash,
    finalizeHash,
    estimateGas,
    bnbPrice,
    isLoadingL1GasPrice,
    l1GasPrice: initL1GasPrice,
    l2GasPrice: initL2GasPrice,
    l1DataGas,
    // historyCache,
  } = props;

  const { getGasPrice } = useGetGasPrice();

  const [l1GasPrice, setL1GasPrice] = useState<number>(initL1GasPrice);
  const [l2GasPrice, setL2GasPrice] = useState<number>(initL2GasPrice || 0);

  const loadGasPrice = useCallback(async () => {
    getGasPrice(env.L1_RPC_URL).then((gasPrice) => {
      setL1GasPrice(Number(gasPrice));
    });
    getGasPrice(env.L2_RPC_URL).then((gasPrice) => {
      setL2GasPrice(Number(gasPrice));
    });
  }, [getGasPrice]);

  useEffect(() => {
    loadGasPrice();
    const interval = setInterval(loadGasPrice, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-console
  // console.log(
  //   'l1GasPrice',
  //   l1GasPrice,
  //   'l2GasPrice',
  //   l2GasPrice,
  //   'l1DataGas',
  //   l1DataGas,
  //   'estimateGas',
  //   estimateGas,
  // );

  /**
   * step 0 - Not yet initiate withdrawal
   * step 1 - Initiate withdrawal completed, waiting to prove - Return in 2 hrs to Prove Withdrawal
   * step 2 - Ready to prove
   * step 3 - Prove withdrawal completed, waiting to finalize - Finalizing Withdrawal
   * step 4 - Ready to finalize
   * step 5 - Finalize withdrawal completed
   */
  return (
    <ProgressContainer>
      <ProgressStep zIndex={1} background={step < 1 ? 'scene.primary.progressBg' : ''}>
        <Flex flexDirection={'row'} alignItems={'center'}>
          {step && step >= 1 ? (
            <ProgressSuccessIcon width={32} height={32} mr={8} />
          ) : (
            <ProgressIcon active={'true'}>1</ProgressIcon>
          )}
          <ProgressName>Initiate Withdrawal</ProgressName>
        </Flex>
        {initiateHash ? (
          <TransactionLink
            href={`${env.COMBO_SCAN[env.NET_ENV]}/tx/${initiateHash}`}
            onClick={() => {
              reportEvent({
                name: GA_MAP.initTxLinkClick,
                data: { name: 'Initiate Tx Link Click' },
              });
            }}
            target="_blank"
          >
            Transaction
            <ExternalLinkIcon w={16} h={16} />
          </TransactionLink>
        ) : (
          <EstimateGas>
            est:{' '}
            {isLoadingL1GasPrice || !l2GasPrice || !l1DataGas || !estimateGas ? (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            ) : (
              `${
                Number(bnbPrice * Number(estimateGas * l2GasPrice + l1DataGas)) < 0.01
                  ? '< $0.01'
                  : `$${Number(bnbPrice * Number(estimateGas * l2GasPrice + l1DataGas)).toFixed(2)}`
              }`
            )}{' '}
            <GasIcon width={16} height={16} ml={2} />
          </EstimateGas>
        )}
      </ProgressStep>

      <ProgressBarWrapper>
        <ProgressBar active={step > 1 ? 'true' : ' false'} />
        <div className={step === 1 ? 'loading-bar' : ''}></div>
        <IconWrapper active={step === 1 ? 'true' : 'false'}>
          {step > 1 ? (
            <ProgressTick width={18} height={18} mr={4} />
          ) : (
            <TimeIcon
              width={18}
              height={18}
              mr={4}
              color={
                step === 1 ? 'scene.primary.normal' : step > 1 ? 'scene.success.normal' : undefined
              }
            />
          )}
          <Box fontWeight={500}>Wait {env.NET_ENV === 'Testnet' ? `15 mins` : `1-2 hrs`}</Box>
        </IconWrapper>
      </ProgressBarWrapper>

      <ProgressStep zIndex={1} background={step === 2 ? 'scene.primary.progressBg' : ''}>
        <Flex flexDirection={'row'} alignItems={'center'}>
          {step && step > 2 ? (
            <ProgressSuccessIcon width={32} height={32} mr={8} />
          ) : (
            <ProgressIcon active={step === 2 ? 'true' : 'false'}>2</ProgressIcon>
          )}
          <ProgressName>Prove Withdrawal</ProgressName>
        </Flex>
        {proveHash ? (
          <TransactionLink
            href={`${env.BSC_SCAN[env.NET_ENV]}/tx/${proveHash}`}
            onClick={() => {
              reportEvent({ name: GA_MAP.proveTxLinkClick, data: { name: 'Prove Tx Link Click' } });
            }}
            target="_blank"
          >
            Transaction
            <ExternalLinkIcon w={16} h={16} />
          </TransactionLink>
        ) : (
          <EstimateGas>
            est:{' '}
            {isLoadingL1GasPrice || l1GasPrice === 0 ? (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            ) : (
              `${
                Number(bnbPrice * Number(env.PROVE_GAS[env.NET_ENV] * l1GasPrice)) < 0.01
                  ? '< $0.01'
                  : `$${Number(bnbPrice * Number(env.PROVE_GAS[env.NET_ENV] * l1GasPrice)).toFixed(
                      2,
                    )}`
              }`
            )}{' '}
            <GasIcon width={16} height={16} ml={2} />
          </EstimateGas>
        )}
      </ProgressStep>

      <ProgressBarWrapper>
        <ProgressBar active={step > 3 ? 'true' : ' false'} />
        <div className={step === 3 ? 'loading-bar' : ''}></div>
        <IconWrapper active={step === 3 ? 'true' : 'false'}>
          {step > 3 ? (
            <ProgressTick width={18} height={18} mr={4} />
          ) : (
            <TimeIcon
              width={18}
              height={18}
              mr={4}
              color={
                step === 3 ? 'scene.primary.normal' : step > 3 ? 'scene.success.normal' : undefined
              }
            />
          )}
          <Box fontWeight={500}>Wait {env.NET_ENV === 'Testnet' ? `30 mins` : `7 days`}</Box>
        </IconWrapper>
      </ProgressBarWrapper>

      <ProgressStep zIndex={1} background={step === 4 ? 'scene.primary.progressBg' : ''}>
        <Flex flexDirection={'row'} alignItems={'center'}>
          {step && step === 5 && initiateHash ? (
            <ProgressSuccessIcon width={32} height={32} mr={8} />
          ) : (
            <ProgressIcon active={step === 4 ? 'true' : 'false'}>3</ProgressIcon>
          )}
          <ProgressName>Finalize Withdrawal</ProgressName>
        </Flex>
        {finalizeHash ? (
          <TransactionLink
            href={`${env.BSC_SCAN[env.NET_ENV]}/tx/${finalizeHash}`}
            onClick={() => {
              reportEvent({
                name: GA_MAP.finalizeTxLinkClick,
                data: { name: 'Finalize Tx Link Click' },
              });
            }}
            target="_blank"
          >
            Transaction
            <ExternalLinkIcon w={16} h={16} />
          </TransactionLink>
        ) : (
          <EstimateGas>
            est:{' '}
            {isLoadingL1GasPrice || l1GasPrice === 0 ? (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            ) : (
              ` ${
                Number(bnbPrice * Number(190000 * l1GasPrice)) < 0.01
                  ? '< $0.01'
                  : `$${Number(bnbPrice * Number(190000 * l1GasPrice)).toFixed(2)}`
              }`
            )}{' '}
            <GasIcon width={16} height={16} ml={2} />
          </EstimateGas>
        )}
      </ProgressStep>
    </ProgressContainer>
  );
};

const TransactionLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${(props: any) => props.theme.colors?.readable.normal};
  cursor: pointer;
  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
`;

const EstimateGas = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

const ProgressContainer = styled(Flex)`
  position: relative;
  margin: 0 auto 24px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  ${mobileMedia} {
    width: 100%;
    margin: 0 0 16px;
  }
`;

const ProgressStep = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 40px;
`;

const ProgressName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  margin-right: 8px;
  ${mobileMedia} {
    width: 128px;
    white-space: nowrap;
    text-align: center;
  }
`;

const ProgressIcon = styled(Flex)<{ active: string }>`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: ${(props: any) =>
    props.active === 'true' ? props.theme.colors.bg?.card : props.theme.colors.readable?.white};
  background: ${(props: any) =>
    props.active === 'true'
      ? props.theme.colors.scene?.primary.normal
      : props.theme.colors.readable?.grey10};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const loadingBar = keyframes`
  0%,
  100% {
    transform: translate(0, 0);
    background-color: #02C076;
  }

  50% {
    transform: translate(0, 50px);
    background-color: #b9b9bb66;
  }
`;

const ProgressBar = styled.div<{ active: string }>`
  position: absolute;
  top: -5px;
  z-index: 2;
  left: 0;
  height: 75px;
  width: 2px;
  background: ${(props: any) =>
    props.active === 'true'
      ? props.theme.colors.scene?.success.progressBar
      : props.theme.colors.readable?.border};
  opacity: ${(props: any) => (props.active === 'true' ? '1' : '0.4')};
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  margin-left: 24px;
  z-index: 1;
  height: 66px;

  .loading-bar {
    position: absolute;
    z-index: 10;
    width: 2px;
    height: 25px;
    top: -5px;
    left: 0;
    animation: ${loadingBar} 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
  }

  ${mobileMedia} {
    width: 64px;
    height: 58px;
    top: 0px;
    left: 0px;
  }
`;

const IconWrapper = styled.div<{ active: string }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 26px;
  z-index: 1;
  top: 50%;
  width: 200px;
  transform: translateY(-50%);
  background: white;
  padding: 4px 12px;
  width: 458px;
  left: 12px;
  border-radius: 40px;
  background-color: ${(props: any) =>
    props.active === 'true' ? props.theme.colors.scene.primary.progressBg : null};
  color: ${(props: any) => props.theme.colors.readable?.pageButton};
  ${mobileMedia} {
    width: 291px;
  }
  @media (max-width: 400px) {
    width: 235px;
  }
`;

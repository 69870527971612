import { Icon, IconProps } from '@totejs/icons';

export const ProgressTick = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0026 6.06659C12.3296 6.28455 12.4116 6.72967 12.1838 7.04983L9.14573 11.3195C8.77954 11.8342 8.03386 11.8846 7.60168 11.424L6.06147 9.78247C5.79731 9.50093 5.80432 9.06055 6.07731 8.78756C6.33289 8.53198 6.739 8.50731 7.02364 8.73007L8.25002 9.68984L11.0755 6.20728C11.3019 5.92817 11.7036 5.86722 12.0026 6.06659Z"
        fill="#02C076"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.75C6.10051 3.75 3.75 6.10051 3.75 9C3.75 11.8995 6.10051 14.25 9 14.25C11.8995 14.25 14.25 11.8995 14.25 9C14.25 6.10051 11.8995 3.75 9 3.75ZM2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9Z"
        fill="#02C076"
      />
    </Icon>
  );
};
